import { Button, ButtonProps, keyframes, styled } from '@punto-ui/react'
import { Div } from '../Div'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

export type IButtonOptionsProps = {
  options: Array<ButtonProps>
  width?: number
} & ButtonProps

export const ButtonOptions = (props: IButtonOptionsProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <DropdownMenu.Root
      open={isOpen}
      onOpenChange={(isOpen) => {
        if (props.isLoading || props.disabled) return
        setIsOpen(isOpen)
      }}
    >
      <DropdownMenu.Trigger
        style={{
          all: 'unset',
          width: '100%',
        }}
      >
        <Button
          css={{
            width: props.width,
          }}
          {...props}
        >
          {props.children}
          <ChevronDownIcon />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenuContent sideOffset={5} align="end" asChild>
          <DropdownMenu.Item>
            <Div
              css={{
                zIndex: 100,
              }}
            >
              {props.options.map((option) => (
                <Button {...option} variant="tertiary" key={option.label} />
              ))}
            </Div>
          </DropdownMenu.Item>
        </DropdownMenuContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

const DropdownMenuContent = styled(DropdownMenu.Content, {
  border: '1px solid',
  borderColor: '$interface_light_down',
  backgroundColor: 'white',
  borderRadius: 8,
  zIndex: 999,
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
})
