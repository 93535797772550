import { useMe } from '@/libs/react-query/hooks'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'

export type PlanType = 'punches' | 'payroll' | 'enterprise'

export type IFeatureTypes =
  | 'liquidation' // done
  | 'punto-ia' // done
  | 'hour-authorization' // done
  | 'template-builder' // done
  | 'receipt-builder' // done
  | 'receipts' // done
  | 'requests' // done
  | 'configuration-salaries' // done
  | 'configuration-vacations' // done
  | 'configuration-aguinaldos' // done
  | 'configuration-liquidation' // done
  | 'payroll' // done

export const payrollPlanFeatures: IFeatureTypes[] = [
  'liquidation',
  'receipts',
  'payroll',
  'configuration-salaries',
  'configuration-vacations',
  'configuration-aguinaldos',
  'configuration-liquidation',
  'hour-authorization',
]

const enterprisePlanFeatures: IFeatureTypes[] = [
  ...payrollPlanFeatures,

  'receipt-builder',
  'punto-ia',
  'requests',
  'template-builder',
]

const featuresByPlan: Record<PlanType, IFeatureTypes[]> = {
  punches: [],
  payroll: payrollPlanFeatures,
  enterprise: enterprisePlanFeatures,
}

export function useIsFeatureInPlan(feature: IFeatureTypes) {
  const { data: company, ...args } = useMyCompany()
  const { data: me } = useMe()

  const planFeatures = company?.plan ? featuresByPlan[company.plan] || [] : []
  const available = planFeatures.includes(feature)

  const isAvailableInPayrollPlan = payrollPlanFeatures.includes(feature)

  const detail =
    me?.isAdmin && !available
      ? `Permitido para administradores, pero disponible en plan ${
          isAvailableInPayrollPlan ? 'Operativo' : 'Avanzado'
        }`
      : !available
      ? `Disponible en plan "${
          isAvailableInPayrollPlan ? 'Operativo' : 'Avanzado'
        }"`
      : undefined

  return { detail, data: available || me?.isAdmin, args }
}
