import { CSS } from '@stitches/react'
import { Div } from '../Div'
import { useEffect, useMemo, useRef, useState } from 'react'
import * as Popover from '@radix-ui/react-popover'
import { Divider, Switch, Text, TextInput } from '@punto-ui/react'
import { CalendarIcon } from '@heroicons/react/24/outline'
import { Calendar } from './Calendar'
import { defaultAppearingAnimationKeyframe } from '@/pages/styles/animations'
import { useOutsideAlerters } from '@/hooks/useOutsideAlerters'
import { Filters } from './Filters'
import dayjs from 'dayjs'
import { CalendarPickerHours } from './Hours'

export interface IInputDatePickerV2Props {
  type: 'range' | 'multiple' | 'single'
  stayOpen?: boolean
  hideInput?: boolean
  maxDates?: number
  isDisabled?: (date: Date) => boolean
  disabled?: boolean
  withoutSubmitButton?: boolean
  modern?: boolean
  errorMessage?: string
  InputComponent?: React.ComponentType<any>
  label?: string
  inputStyles?: CSS
  placeholder?: string
  priorityPlaceholder?: string
  help?: React.ReactNode
  disclaimer?: string

  withHours?: boolean
  isHoursEnabled?: boolean
  onChangeHoursEnabled?: (hours: boolean) => void

  withMultipleOption?: boolean
  onCalendarTypeChange?: (type: 'range' | 'multiple' | 'single') => void

  dates?: Date[]
  onChangeDates: (dates: Array<Date | null>) => void
}

export const InputDatePickerV2 = ({
  InputComponent = TextInput,
  ...props
}: IInputDatePickerV2Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [calendarReferenceDate, setCalendarReferenceDate] = useState<Date>(
    props.dates?.[0] ? props.dates[0] : new Date(),
  )

  const hasSetCalendarReferenceDate = useRef(false)

  useEffect(() => {
    if (hasSetCalendarReferenceDate.current || !props.dates?.[0]) {
      return
    }

    setCalendarReferenceDate(props.dates?.[0] ? props.dates?.[0] : new Date())
    hasSetCalendarReferenceDate.current = true
  }, [props.dates])

  const calendarContainerRef = useRef<HTMLDivElement>(null)
  const calendarDropdownRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const timeoutRef = useRef<any>(null)

  useOutsideAlerters([calendarContainerRef, calendarDropdownRef], () => {
    setIsOpen(false)
    setIsFocused(false)
  })

  const inputValue = useMemo(() => {
    if (props.dates?.length === 0) {
      return ''
    }

    if (props.type === 'multiple') {
      return props.dates
        ?.map((date) => dayjs(date).format('DD/MM/YYYY'))
        .join(', ')
    }

    if (props.type === 'range') {
      let text = ''

      if (props.dates?.[0]) {
        text += dayjs(props.dates[0]).format('DD/MM/YYYY')
      }

      if (props.dates?.[1]) {
        text += ` - ${dayjs(props.dates[1]).format('DD/MM/YYYY')}`
      }

      return text
    }

    return dayjs(props.dates?.[0]).format('DD/MM/YYYY')
  }, [props.dates])

  return (
    <Popover.Root
      open={isFocused || isOpen}
      onOpenChange={(newOpen) => {
        if (!newOpen && isFocused) {
          return
        }

        setIsOpen(newOpen)

        if (newOpen) {
          timeoutRef.current = setTimeout(() => {
            if (inputRef.current) {
              inputRef.current.focus()
            }
          }, 15)
        }
      }}
    >
      <Div ref={calendarContainerRef}>
        <Popover.Trigger
          style={{
            all: 'unset',
            width: '100%',
          }}
        >
          <InputComponent
            disabled={props.disabled}
            disclaimer={props.disclaimer}
            label={props.label}
            ref={inputRef}
            placeholder={props.placeholder}
            errorMessage={props.errorMessage}
            icon={<CalendarIcon />}
            value={inputValue}
            onFocus={() => {
              setIsFocused(true)
            }}
            onBlur={() => {
              setIsFocused(false)
            }}
            onChange={(e: any) => {
              e.preventDefault()
              if (props.type === 'multiple') {
                return null
              } else {
                // handleChangeInputData(e)
              }
            }}
          />
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content sideOffset={5} align="start" asChild>
            <Div
              ref={calendarDropdownRef}
              css={{
                zIndex: 9999999,
                // maxHeight: 350,
                width: 250,

                pointerEvents: 'auto',
                overflowY: 'hidden',
                overflowX: 'hidden',

                backgroundColor: '$interface_light_pure',
                borderRadius: '$md',

                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',

                boxShadow: '0px 0px 16px rgba(52, 58, 64, 0.08)',
                border: 'solid 1px $interface_light_down',

                transition: 'opacity 0.5s ease-in-out',
                animation: `${defaultAppearingAnimationKeyframe} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
              }}
            >
              <Filters
                setCalendarDate={setCalendarReferenceDate}
                calendarDate={calendarReferenceDate}
              />
              <Calendar
                {...props}
                calendarDate={calendarReferenceDate}
                dates={props.dates || []}
              />

              {props.withMultipleOption && (
                <Div>
                  <Divider
                    css={{
                      height: 1,
                    }}
                  />
                  <Div
                    css={{
                      padding: '$2',
                      paddingLeft: '$2',
                      paddingRight: '$2',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text variant="caption">
                      {props.type === 'multiple'
                        ? 'Multiplos dias'
                        : 'Rango de fechas'}
                    </Text>
                    <Switch
                      checked={props.type === 'multiple'}
                      onCheckedChange={(value) => {
                        const isRanged = !value

                        if (isRanged) {
                          const adjustedValue = props.dates?.slice(0, 2)
                          props.onChangeDates?.(adjustedValue as any)
                        }

                        props.onCalendarTypeChange?.(
                          value ? 'multiple' : 'range',
                        )
                      }}
                    />
                  </Div>
                </Div>
              )}
              {props.withHours && props.type !== 'multiple' && (
                <CalendarPickerHours {...props} dates={props.dates || []} />
              )}
            </Div>
          </Popover.Content>
        </Popover.Portal>
      </Div>
    </Popover.Root>
  )
}
