import React, { useMemo, useRef } from 'react'
import {
  ArrowPathIcon,
  UsersIcon,
  CalendarDaysIcon,
  ShieldCheckIcon,
  Cog6ToothIcon,
  TableCellsIcon,
  XMarkIcon,
  DocumentChartBarIcon,
  SunIcon,
  BugAntIcon,
  BuildingStorefrontIcon,
  AcademicCapIcon,
  NewspaperIcon,
  MicrophoneIcon,
  ChatBubbleLeftRightIcon,
  CurrencyDollarIcon,
  ChartPieIcon,
  ComputerDesktopIcon,
  FolderIcon,
  BellIcon,
  DocumentPlusIcon,
  ArrowUpTrayIcon,
} from '@heroicons/react/24/outline'

import { Div, SideBar, Touchable } from '@/components'
import {
  Button,
  ButtonProps,
  Divider,
  Progress,
  TagProps,
  Text,
  Tooltip,
  styled,
} from '@punto-ui/react'
import { useUserPermissions } from '@/libs/react-query/hooks/useUserPermissions'
import * as Tabs from '@radix-ui/react-tabs'
import { useHorizontalScroll } from '@/hooks/useHorizontalScroll'
import { useMe } from '@/libs/react-query/hooks'

import { FaceIdIcon } from '@/assets/icons/FaceIdIcon'
import { useUnreadNotificationsCount } from '@/libs/react-query/hooks/notifications/useUnreadNotificationsCount'
import { useIsModulesByPlan } from '@/hooks/useModulesByPlan'
import { ButtonOptions } from '@/components/ButtonOptions'

export interface IRoute {
  permission?: string
  label: string
  path: string
  icon: React.ReactNode
  disabled?: boolean
  tag?: string
  tagVariant?: TagProps['variant']
  badge?: string
}

export const useRoutes = () => {
  const { data: permissions } = useUserPermissions()
  const { data: me } = useMe()
  const { data: unreadNotificationsCount } = useUnreadNotificationsCount()

  const allowedModules = useIsModulesByPlan()

  const routes = useMemo(() => {
    const permissionedRoutes: IRoute[] = []
    const allRoutes = [
      {
        permission: 'company',
        label: 'Empresa',
        path: '/empresa',
        icon: <BuildingStorefrontIcon strokeWidth={2} />,
      },
      {
        permission: 'workers',
        label: 'Colaboradores',
        path: '/colaboradores',
        icon: <UsersIcon strokeWidth={2} />,
      },
      {
        permission: 'shift',
        label: 'Turnos',
        path: '/turnos',
        icon: <CalendarDaysIcon strokeWidth={2} />,
      },
      {
        permission: 'punches',
        label: 'Asistencia',
        path: '/marcaciones',
        icon: <FaceIdIcon strokeWidth={2} />,
      },
      {
        permission: 'moves',
        label: 'Movimientos',
        path: '/movimientos-v2',
        icon: <ArrowPathIcon strokeWidth={2} />,
      },
      {
        permission: 'payments',
        label: 'Pagos',
        path: '/pagos-v2',
        icon: <CurrencyDollarIcon strokeWidth={2} />,
      },
      {
        permission: 'vacation',
        label: 'Vacaciones',
        path: '/vacaciones',
        icon: <SunIcon strokeWidth={2} />,
      },
      {
        permission: 'payroll',
        label: 'Planilla Nominal',
        path: '/planilla',
        icon: <TableCellsIcon strokeWidth={2} />,
      },
      // {
      //   permission: 'reports',
      //   label: 'Reportes',
      //   path: '/reportes-v1',
      //   icon: <DocumentChartBarIcon strokeWidth={2} />,
      // },
      {
        permission: 'reports',
        label: 'Generación de Reportes',
        path: '/reportes-v2',
        disabled: false,
        icon: <ChartPieIcon strokeWidth={2} />,
      },
      // {
      //   label: 'Cuestionarios',
      //   path: '/cuestionarios',
      //   icon: <ClipboardDocumentListIcon strokeWidth={2} />,
      // },
      {
        permission: 'permisos',
        label: 'Permisos y Seguridad',
        path: '/permisos-v2',
        icon: <ShieldCheckIcon strokeWidth={2} />,
      },
      {
        permission: 'audit',
        label: 'Auditoría',
        path: '/auditoria',
        icon: <ComputerDesktopIcon strokeWidth={2} />,
      },
      {
        permission: 'notifications',
        label: 'Notificaciones',
        path: '/notificaciones',
        icon: <BellIcon strokeWidth={2} />,
        badge: unreadNotificationsCount
          ? unreadNotificationsCount.toString()
          : undefined,
      },
      {
        permission: 'imports',
        label: 'Importaciones',
        path: '/imports',
        icon: <ArrowUpTrayIcon strokeWidth={2} />,
      },
      {
        permission: 'requests',
        label: 'Solicitaciones',
        path: '/solicitaciones',
        icon: <ChatBubbleLeftRightIcon strokeWidth={2} />,
      },
      {
        permission: 'documents',
        label: 'Documentos',
        path: '/documents',
        icon: <FolderIcon strokeWidth={2} />,
      },
      {
        permission: 'configurations',
        label: 'Configuraciones',
        path: '/configuraciones',
        icon: <Cog6ToothIcon strokeWidth={2} />,
      },
    ]

    allRoutes.forEach((route) => {
      const userHasPermissionForRoute = permissions?.some((permission) =>
        permission.includes(route.permission),
      )

      const moduleInPlan = allowedModules.find((x) =>
        x.module.includes(route.permission),
      )

      if (!moduleInPlan?.available) {
        permissionedRoutes.push({
          ...route,
          disabled: true,
          tag: moduleInPlan?.planLabel
            ? `Plan ${moduleInPlan?.planLabel}`
            : undefined,
          tagVariant:
            moduleInPlan?.planLabel === 'Avanzado' ? 'positive' : 'neutral',
        })

        return
      }

      if (userHasPermissionForRoute) {
        permissionedRoutes.push(route)
      }
    })

    if (me?.isAdmin) {
      permissionedRoutes.push({
        label: 'Admin',
        path: '/admin',
        icon: <BugAntIcon strokeWidth={2} />,
      })
    }

    return permissionedRoutes
  }, [permissions, me, unreadNotificationsCount, allowedModules])

  const publicRoutes = [
    {
      label: 'Academia Punto OK',
      path: '/academy',
      icon: <AcademicCapIcon strokeWidth={2} />,
      disabled: !me?.isAdmin,
    },
    {
      label: 'Blog',
      path: '/blog',
      disabled: true,
      icon: <NewspaperIcon strokeWidth={2} />,
    },
    {
      label: 'PuntoCast',
      disabled: true,
      path: '/punto-cast',
      icon: <MicrophoneIcon strokeWidth={2} />,
    },
  ]

  return {
    routes,
    publicRoutes,
  }
}

export interface IRouteTab {
  icon: React.ReactNode
  label: string
  value: string
  scrollable?: boolean
  component: React.ReactNode
  disabled?: boolean
  disableMessage?: string
  closeable?: boolean
  handleClose?: () => void
}

export type IRouteAction =
  | {
      type?: 'button'
      icon: React.ReactNode
      label: string
      variant: ButtonProps['variant']
      size?: ButtonProps['size']
      action: () => void
      disabled?: boolean
      disableMessage?: string
      isLoading?: boolean
      width?: number

      component?: React.ReactNode
    }
  | {
      type: 'options'
      options: Array<ButtonProps>
      icon: React.ReactNode
      label: string
      variant: ButtonProps['variant']
      size?: ButtonProps['size']
      action: () => void
      disabled?: boolean
      disableMessage?: string
      isLoading?: boolean
      width?: number
    }

export interface IRouteCustomAction {
  component: React.ReactNode
}

interface SideBarRouterProps {
  tabs?: IRouteTab[]
  children?: React.ReactNode
  action?: React.ReactNode
  actions?: IRouteAction[]
  customActions?: IRouteCustomAction[]
  defaultTab?: string
  tab?: string
  onChangeTab?: (tab: string) => void
  isLoading?: boolean
  setTab?: (tab: string) => void
  onlyTabs?: boolean
}

export const Router = ({
  tabs,
  action,
  actions,
  customActions,
  defaultTab,
  children,
  tab,
  isLoading,
  onChangeTab,
  setTab,
}: SideBarRouterProps) => {
  const { ref: scrollRef } = useHorizontalScroll<HTMLDivElement>()

  const contentRef = useRef<HTMLDivElement | null>(null)

  return (
    <SideBarContainer>
      <SideBar />
      {tabs && (
        <TabsRoot
          ref={contentRef}
          defaultValue={defaultTab}
          value={tab}
          onValueChange={onChangeTab}
        >
          <TabsList>
            <Div
              ref={scrollRef}
              css={{
                display: 'flex',
                alignItems: 'center',
                maxWidth: '95vw',
                maxHeight: 40,
                minHeight: 40,
                overflow: 'auto',
                height: '100%',
                width: '100%',

                '&::-webkit-scrollbar': {
                  width: 0,
                  height: 0,
                },
              }}
            >
              {tabs.map((tab) => (
                <TabTrigger key={tab.value} tab={tab} setTab={setTab} />
              ))}
              {isLoading && (
                <Div css={{ marginLeft: '$2', marginRight: 'auto' }}>
                  <Progress />
                </Div>
              )}
            </Div>

            <Div
              css={{
                marginLeft: 'auto',
                marginRight: '$4',
              }}
            >
              {!!action && (
                <Div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {action}
                </Div>
              )}
              {!!actions && (
                <Div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '$2',
                  }}
                >
                  {actions.map((action) => {
                    if (action?.type === 'options') {
                      return (
                        <ButtonOptions
                          key={action.label}
                          {...action}
                          type="button"
                        />
                      )
                    }

                    if (action.component) {
                      return <Div key={action.label}>{action.component}</Div>
                    }

                    return (
                      <Div key={action.label}>
                        {action.disableMessage ? (
                          <Tooltip
                            message={action.disableMessage}
                            key={action.label}
                            arrow
                            side={'left'}
                          >
                            <Div>
                              <Button
                                size={action.size || 'md'}
                                isLoading={action.isLoading}
                                variant={action.variant || 'tertiary'}
                                icon={action.icon}
                                disabled={action.disabled}
                                onClick={action.action}
                                css={{
                                  width: action.width,
                                }}
                              >
                                {action.label}
                              </Button>
                            </Div>
                          </Tooltip>
                        ) : (
                          <Button
                            variant={action.variant || 'tertiary'}
                            icon={action.icon}
                            size={action.size || 'md'}
                            disabled={action.disabled}
                            isLoading={action.isLoading}
                            onClick={action.action}
                            css={{
                              width: action.width,
                            }}
                          >
                            {action.label}
                          </Button>
                        )}
                      </Div>
                    )
                  })}
                </Div>
              )}
              {!!customActions && (
                <Div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '$2',
                    gap: '$2',
                  }}
                >
                  {customActions.map((action, index) => (
                    <Div key={index}>{action.component}</Div>
                  ))}
                </Div>
              )}
            </Div>
          </TabsList>
          <Div
            css={{
              overflow: 'scroll',
              maxHeight: '100vh',
              maxWidth: '100vw',
              width: '100%',
              height: '100%',
              // minHeight: '100vh',

              minHeight: 'calc(100vh - 60px)',

              flex: 1,
              display: 'flex',
              // background: '$interface_light_up',
            }}
          >
            {tabs.map((tab) => (
              <TabsContent
                scrollable={tab.scrollable}
                value={tab.value}
                key={tab.value}
              >
                {tab.component}
              </TabsContent>
            ))}
          </Div>
        </TabsRoot>
      )}
      {!tabs && (
        <Div
          css={{
            maxHeight: '100vh',
            width: '100%',
          }}
        >
          {children}
        </Div>
      )}
    </SideBarContainer>
  )
}

const TabTrigger = ({
  tab,
  setTab,
}: {
  tab: IRouteTab
  setTab?: (tab: string) => void
}) => {
  return (
    <Tooltip message={tab.disableMessage} arrow>
      <Div
        css={{
          display: 'flex',
          height: '100%',

          opacity: tab.disabled ? 0.5 : 1,
          cursor: tab.disabled ? 'not-allowed' : 'pointer',
        }}
      >
        <TabsTrigger
          disabled={tab.disabled}
          value={tab.value}
          key={tab.value}
          onClick={() => setTab?.(tab.value)}
        >
          {tab.icon}
          <Text
            css={{
              marginLeft: '$2',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {tab.label}
          </Text>
          {tab.closeable && (
            <Touchable
              onClick={tab.handleClose}
              css={{
                marginTop: 3,
                marginLeft: '$2',
                svg: {
                  color: '$interface_dark_deep',
                  height: 16,
                  width: 16,
                },
              }}
            >
              <XMarkIcon />
            </Touchable>
          )}
        </TabsTrigger>
        <Divider
          orientation="vertical"
          css={{
            margin: 'auto 0',
            height: 24,
          }}
        />
      </Div>
    </Tooltip>
  )
}

const SideBarContainer = styled('div', {
  display: 'flex',
  maxHeight: '100vh',
  maxWidth: '100vw',
})

const TabsRoot = styled(Tabs.Root, {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxHeight: 'calc(100vh)',
  overflow: 'hidden',
})

const TabsList = styled(Tabs.List, {
  display: 'flex',
  alignItems: 'center',

  overflow: 'hidden',
  maxWidth: '100vw',
  height: '100%',
  width: '100%',
  maxHeight: 44,
  borderBottom: '1px solid',
  borderColor: '$interface_light_down',

  background: '$interface_light_pure',
})

const TabsTrigger = styled(Tabs.Trigger, {
  all: 'unset',
  userSelect: 'none',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  color: '$interface_dark_deep',

  '&:hover': {
    color: '$brand_primary_pure',
  },

  paddingRight: '$6',
  paddingLeft: '$6',

  borderBottom: '1px solid',
  borderColor: '$interface_light_pure',

  transition: 'all 0.3s ease-in-out',

  '&[data-state="active"]': {
    [`${Text}`]: {
      color: '$brand_primary_pure',
    },

    svg: {
      color: '$brand_primary_pure',
    },

    borderBottom: '1px solid',
    borderColor: '$brand_primary_pure',
  },

  svg: {
    height: 20,
    width: 20,
    color: '$interface_dark_deep',
  },
})

const TabsContent = styled(Tabs.Content, {
  flex: 1,
  overflow: 'hidden',
  backgroundColor: '$interface_light_up',
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 6,
  outline: 'none',

  position: 'relative',

  variants: {
    scrollable: {
      true: {
        overflow: 'scroll',
      },
      false: {
        overflow: 'hidden',
      },
    },
  },
})
