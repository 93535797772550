import { IDefaultApiPagination } from '../pagination'
import { IDefaultModuleContextPropsV2 } from '../context'

export const paymentOptions = [
  {
    label: 'Anticipo',
    value: 'antecipate',
  },
  {
    label: 'Anticipo de Aguinaldo',
    value: 'aguinaldo_antecipate',
  },
  {
    label: 'Prestamo',
    value: 'loan',
  },
  {
    label: 'Perdidas y Averias',
    value: 'lost',
  },
  {
    label: 'Compras',
    value: 'purchase',
  },
  {
    label: 'Desc. Judiciales',
    value: 'judicial',
  },
  {
    label: 'Pagos en excesso',
    value: 'excess',
  },
  {
    label: 'Otros Descuentos',
    value: 'otherDiscount',
  },
  {
    label: 'Movilidad',
    value: 'mobility',
  },
  {
    label: 'Bonus',
    value: 'bonus',
  },
  {
    label: 'Gratificaciones',
    value: 'gift',
  },
  {
    label: 'Comisiones',
    value: 'commission',
  },
  {
    label: 'Otros Ingresos',
    value: 'other',
  },
  {
    label: 'Aporte manual IPS 9%',
    value: 'ipsPayment9',
  },
  {
    label: 'Aporte manual IPS 16%',
    value: 'ipsPayment16',
  },
]

export const paymentsHeader = [
  'Movilidad',
  'Comisiones',
  'Bonus',
  'Gratificaciones',
  'Otros Ingresos',
]

export const disabledIpsPaymentTypes = [
  'antecipate',
  'aguinaldo_antecipate',
  'loan',
  'lost',
  'purchase',
  'judicial',
  'excess',
]

export const fromRawDebitPaymentToHeader = {
  antecipate: 'Anticipo',
  aguinaldo_antecipate: 'Anticipo de Aguinaldo',
  loan: 'Prestamo',
  lost: 'Perdidas y Averias',
  purchase: 'Compras',
  judicial: 'Desc. Judiciales',
  excess: 'Pagos en excesso',
  otherDiscount: 'Otros Descuentos',
}

export const fromRawCreditPaymentToHeader = {
  mobility: 'Movilidad',
  commission: 'Comisiones',
  bonus: 'Bonus',
  gift: 'Gratificaciones',
  other: 'Otros Ingresos',
}

export const debitPaymentsHeader = [
  'Anticipo',
  'Prestamo',
  'Perdidas y Averias',
  'Compras',
  'Desc. Judiciales',
  'Pagos en excesso',
  'Otros Descuentos',
]

export const debitPaymentsHeaderRaw = [
  'antecipate',
  'loan',
  'lost',
  'purchase',
  'judicial',
  'excess',
  'otherDiscount',
] as const

export const paymentsHeaderRaw = [
  'mobility',
  'commission',
  'bonus',
  'gift',
  'other',
] as const

export type IPaymentTypes =
  | 'antecipate'
  | 'aguinaldo_antecipate'
  | 'loan'
  | 'lost'
  | 'purchase'
  | 'judicial'
  | 'excess'
  | 'otherDiscount'
  | 'mobility'
  | 'commission'
  | 'bonus'
  | 'gift'
  | 'other'
  | 'ipsPayment9'
  | 'ipsPayment16'

export type IValueTypes = 'VALUE' | 'FULL_PERCENTAGE' | 'SALARY_PERCENTAGE'

export const valueTypeOptions = [
  {
    label: 'Valor nominal',
    value: 'VALUE',
  },
  {
    label: 'Porcentaje del todo',
    value: 'FULL_PERCENTAGE',
  },
  {
    label: 'Porcentaje del salario',
    value: 'SALARY_PERCENTAGE',
  },
]

export type IPaymentTypesLabel =
  | 'Anticipo'
  | 'Anticipo de Aguinaldo'
  | 'Prestamo'
  | 'Perdidas y Averias'
  | 'Compras'
  | 'Desc. Judiciales'
  | 'Pagos en excesso'
  | 'Otros Descuentos'
  | 'Movilidad'
  | 'Bonus'
  | 'Gratificaciones'
  | 'Comisiones'
  | 'Otros Ingresos'
  | 'Aporte manual IPS 9%'
  | 'Aporte manual IPS 16%'

export const paymentsDictionary: Record<IPaymentTypes, IPaymentTypesLabel> = {
  antecipate: 'Anticipo',
  aguinaldo_antecipate: 'Anticipo de Aguinaldo',
  loan: 'Prestamo',
  lost: 'Perdidas y Averias',
  purchase: 'Compras',
  judicial: 'Desc. Judiciales',
  excess: 'Pagos en excesso',
  otherDiscount: 'Otros Descuentos',
  mobility: 'Movilidad',
  bonus: 'Bonus',
  gift: 'Gratificaciones',
  commission: 'Comisiones',
  other: 'Otros Ingresos',
  ipsPayment9: 'Aporte manual IPS 9%',
  ipsPayment16: 'Aporte manual IPS 16%',
}

export const valueTypeDictionary: Record<IValueTypes, string> = {
  VALUE: 'Valor nominal',
  FULL_PERCENTAGE: 'Porcentaje del todo',
  SALARY_PERCENTAGE: 'Porcentaje del salario',
}

export interface IPaymentDate {
  id: string
  date: string
  start_date: any
  end_date: any
  identifier_id: string
  payment_id: string
  created_at: string
  updated_at: string
  deleted_at: any
}

export interface IPaymentUser {
  id: string
  user_id: string

  name: string
  image_url: string

  payment_final_value: number
  should_include_in_ips: boolean

  identifier_id: string
  payment_id: string
  created_at: string
  updated_at: string
  deleted_at: any
}

export interface IPayment {
  id: string
  batch_id: string
  batch_number?: number
  payment_type: IPaymentTypes
  payroll_label: string
  title: string
  description: string
  value: number
  payment_date: string
  value_type: IValueTypes
  discount_ips9: boolean
  identifier_id: string
  created_at: string
  updated_at: string
  deleted_at: any
  dates: IPaymentDate[]
  users: IPaymentUser[]
}

export interface IPaymentBatch {
  id: string
  number: number
  title: string
  description?: string
  identifier_id: string
  created_at: string
  updated_at: string
  deleted_at: string

  payments: IPayment[]
}

export interface ICreatePaymentPayload {
  title: string
  description?: string
  payment_type: string
  payroll_label: string
  payment_date: string
  value_type: string
  discount_ips9: boolean
  value: number
  dates: {
    date: string
    start_date: string
    end_date: string
  }[]
  users: {
    id: string
    ips: boolean
  }[]
}

export interface IUpdatePaymentPayload extends ICreatePaymentPayload {
  id: string
}

export type IPaginatedPayments = IDefaultApiPagination<IPayment>
export type IPaginatedPaymentBatches = IDefaultApiPagination<IPaymentBatch>
export type IPaymentsContextProps = IDefaultModuleContextPropsV2
