import { queryClient } from '@/libs/react-query'
import {
  IReport,
  IRequestReceiptExportDTO,
} from '@/libs/react-query/types/reports'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleRequestExportReceipt = async (data: IRequestReceiptExportDTO) => {
  const response = await apiV2.post<IReport>(
    `/report-builder/request-export-receipt`,
    data,
  )
  queryClient.invalidateQueries()

  return response.data
}

export const useHandleRequestExportReceipt = () => {
  const mutation = useCustomMutation(
    ['useHandleExportReceipt'],
    handleRequestExportReceipt,
  )

  return mutation
}
