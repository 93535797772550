import { Button, Text } from '@punto-ui/react'
import { Div } from '../Div'
import {
  BanknotesIcon,
  CalendarDaysIcon,
  CheckCircleIcon,
  CloudArrowDownIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline'
import { formatCurrency } from '@/utils/numbers/currency'
import dayjs from 'dayjs'
import { DropdownV2 } from '../DropdownV2'
import { IReceiptBuilderTypes } from '@/pages/reportes-v2/ReceiptBuilder/type'
import { useReportReceipts } from '@/libs/react-query/hooks/useReportReceipts'
import { useEffect, useState } from 'react'
import { useHandleRequestExportReceipt } from '@/libs/react-query/mutations/reports/useHandleRequestExportReceipt'
import { useAvailableCells } from '@/libs/react-query/hooks'
import { useReports } from '@/libs/react-query/hooks/useReports'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useDownload } from '@/contexts/DownloadContext'

export interface IExportReceiptDialogProps {
  title: string
  description: string

  date: string
  value: number
  users: number

  type: IReceiptBuilderTypes

  exportingReportId: string | null

  handleClose: () => void
  handleConfirm: (id: string) => void
}

export const ExportReceiptDialog = (data: IExportReceiptDialogProps) => {
  const { downloadFile } = useDownload()
  const [receiptId, setReceiptId] = useState('')

  const { data: receipts } = useReportReceipts({
    page: 0,
    perPage: 50,
    types: [data.type],
  })

  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('reports')),
  )

  const { data: reports, isFetching: isFetchingReports } = useReports({
    page: 0,
    cellsIds: availableCells?.map((c) => c.id) || [],
    perPage: 5,
  })

  useEffect(() => {
    if (receipts?.data?.length) {
      const favoriteReceipt = receipts.data.find(
        (r) => r.favorite && r.type === data.type,
      )

      if (favoriteReceipt) {
        setReceiptId(favoriteReceipt.id)
      } else {
        setReceiptId(receipts.data[0].id)
      }
    }
  }, [receipts])

  const { isLoading: isLoadingRequestExport } = useHandleRequestExportReceipt()

  const exportedReport = reports?.data.find(
    (r) => r.id === data.exportingReportId,
  )

  return (
    <Div
      css={{
        minWidth: 500,
      }}
    >
      <Div
        css={{
          padding: '$4',
        }}
      >
        <Text
          variant={'subtitle1'}
          css={{
            color: '$interface_dark_deep',
            marginBottom: 4,
          }}
        >
          {data.title}
        </Text>
        <Text
          variant={'description'}
          css={{
            color: '$interface_dark_down',
          }}
        >
          {data.description}
        </Text>

        <Div
          css={{
            display: 'flex',
            alignItems: 'center',

            paddingTop: '$4',

            // justifyContent: 'space-between',
            '> div': {
              flex: 1,
            },

            svg: {
              height: 24,
              width: 24,
              color: '$interface_dark_down',
              marginRight: '$2',
              strokeWidth: 1.5,
            },
          }}
        >
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CalendarDaysIcon />
            <Text
              variant={'description'}
              css={{
                color: '$interface_dark_down',
              }}
            >
              {dayjs(data.date).format('DD/MM/YYYY')}
            </Text>
          </Div>
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <BanknotesIcon />
            <Text
              variant={'description'}
              css={{
                color: '$interface_dark_down',
              }}
            >
              {formatCurrency(data.value)}
            </Text>
          </Div>
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <UserCircleIcon />
            <Text
              variant={'description'}
              css={{
                whiteSpace: 'nowrap',
                color: '$interface_dark_down',
              }}
            >
              {data.users} colaboradores
            </Text>
          </Div>
        </Div>
      </Div>

      <Div
        css={{
          width: '100%',
          height: 1,
          background: '$interface_light_down',
        }}
      />

      <Div
        css={{
          padding: '$4',
        }}
      >
        <DropdownV2
          onChangeValue={(value) => {
            setReceiptId(value.value)
          }}
          value={receiptId}
          label="Seleccione el modelo de recibo"
          disclaimer="Este modelo es generado en el módulo de reportes"
          options={
            receipts?.data?.map((r) => ({
              label: r.name,
              value: r.id,
            })) || []
          }
          defaultOption={{
            label: receipts?.data?.[0]?.name || '',
            value: receipts?.data?.[0]?.id || '',
          }}
        />
      </Div>

      <Div
        css={{
          width: '100%',
          height: 1,
          marginBottom: '$4',
          background: '$interface_light_down',
        }}
      />

      <Div
        css={{
          display: 'flex',
          justifyContent: 'flex-end',

          paddingRight: '$4',
          paddingLeft: '$4',
          paddingBottom: '$2',

          gap: '$2',
        }}
      >
        {data.exportingReportId && (
          <Button
            disabled={!receiptId || !exportedReport?.url}
            isLoading={isLoadingRequestExport || !exportedReport?.url}
            variant="secondary"
            icon={<CloudArrowDownIcon />}
            onClick={() => {
              if (exportedReport?.url) {
                downloadFile(exportedReport.url, exportedReport.type)
              }
            }}
          >
            Descargar
          </Button>
        )}
        <Button
          disabled={!receiptId}
          isLoading={isLoadingRequestExport}
          icon={<CheckCircleIcon />}
          onClick={() => data.handleConfirm(receiptId)}
        >
          Generar
        </Button>
      </Div>
    </Div>
  )
}
