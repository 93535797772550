import { Divider, styled, Switch, Text } from '@punto-ui/react'
import { Div } from '../Div'
import dayjs from 'dayjs'
import { IInputDatePickerV2Props } from '.'
import { useEffect, useRef, useState } from 'react'
import { typeDecoder } from '../Forms/ControlledInput/toPattern'

const HourInput = styled('input', {
  all: 'unset',
  maxWidth: 50,

  color: '$interface_dark_down',
  fontFamily: '$default',
  fontSize: '$sm',
})

export const CalendarPickerHours = (
  props: IInputDatePickerV2Props & {
    dates: Date[]
  },
) => {
  const [firstHour, setFirstHour] = useState(
    props.dates[0] ? dayjs(props.dates[0]).format('HH:mm') : '',
  )
  const [secondHour, setSecondHour] = useState(
    props.dates[1] ? dayjs(props.dates[1]).format('HH:mm') : '',
  )

  const firstDateSaved = useRef<Date | null>(props.dates[0] ?? null)
  const secondDateSaved = useRef<Date | null>(props.dates[1] ?? null)

  useEffect(() => {
    const newFirstDate = props.dates[0] ?? null
    const newSecondDate = props.dates[1] ?? null

    const firstDateIsDifferent = !dayjs(newFirstDate).isSame(
      dayjs(firstDateSaved.current),
      'date',
    )
    const secondDateIsDifferent = !dayjs(newSecondDate).isSame(
      dayjs(secondDateSaved.current),
      'date',
    )

    if (firstDateIsDifferent || secondDateIsDifferent) {
      setFirstHour(newFirstDate ? dayjs(newFirstDate).format('HH:mm') : '')
      setSecondHour(newSecondDate ? dayjs(newSecondDate).format('HH:mm') : '')

      firstDateSaved.current = newFirstDate
      secondDateSaved.current = newSecondDate
    }
  }, [props.dates])

  return (
    <Div>
      <Divider
        css={{
          height: 1,
        }}
      />
      <Div
        css={{
          padding: '$2',
          paddingLeft: '$2',
          paddingRight: '$2',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Text variant="caption">Incluir horas</Text>
          <Switch
            checked={props.isHoursEnabled}
            onCheckedChange={props.onChangeHoursEnabled}
          />
        </Div>
        {props.isHoursEnabled && (
          <Div
            css={{
              marginTop: '$2',
            }}
          >
            <Div
              css={{
                display: 'flex',
                alignItems: 'center',

                height: 32,

                border: 'solid 1px $interface_light_down',
                padding: '$1',
                borderRadius: '$sm',

                marginBottom: '$2',
              }}
            >
              <Text
                variant="description"
                css={{
                  color: '$interface_dark_down',
                }}
              >
                {props.dates[0]
                  ? dayjs(props.dates[0]).format('DD MMMM YYYY')
                  : ''}
              </Text>
              <Divider
                orientation="vertical"
                css={{
                  marginLeft: '$2',
                  marginRight: '$2',
                  width: 1,
                  height: 20,
                }}
              />
              <HourInput
                value={firstHour}
                onChange={(e) => {
                  const formatted = typeDecoder('hour')
                    .input(e.target.value)
                    .toString()

                  setFirstHour(formatted)

                  const [hour, minute] = formatted.split(':')

                  const firstDateWithHour = dayjs(props.dates[0])
                    .set('hour', Number(hour || 0))
                    .set('minute', Number(minute || 0))
                    .toDate()

                  const newDates = [firstDateWithHour, props.dates[1]]

                  props.onChangeDates(newDates)
                }}
              />
            </Div>
            <Div
              css={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'space-between',

                height: 32,

                border: 'solid 1px $interface_light_down',
                padding: '$1',
                borderRadius: '$sm',
              }}
            >
              <Text
                variant="description"
                css={{
                  color: '$interface_dark_down',
                }}
              >
                {props.dates[1]
                  ? dayjs(props.dates[1]).format('DD MMMM YYYY')
                  : ''}
              </Text>
              <Divider
                orientation="vertical"
                css={{
                  marginLeft: '$2',
                  marginRight: '$2',
                  width: 1,
                  height: 20,
                }}
              />
              <HourInput
                value={secondHour}
                onChange={(e) => {
                  const formatted = typeDecoder('hour')
                    .input(e.target.value)
                    .toString()

                  const [hour, minute] = formatted.split(':')

                  const secondDateWithHour = dayjs(props.dates[1])
                    .set('hour', Number(hour || 0))
                    .set('minute', Number(minute || 0))
                    .toDate()

                  setSecondHour(formatted)

                  const newDates = [props.dates[0], secondDateWithHour]

                  props.onChangeDates(newDates)
                }}
              />
            </Div>
          </Div>
        )}
      </Div>
    </Div>
  )
}
