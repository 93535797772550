import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { useMe } from '@/libs/react-query/hooks/useMe'

import { IPaginatedPayments, IPaymentsContextProps } from '../../types'

const fetchPayment = async (props: IPaymentsContextProps) => {
  const response = await apiV2.post<IPaginatedPayments>(
    '/payment/list-payments',
    {
      ids: props.ids,
      page: props.page,
      perPage: props.pageSize,
      period_end: props.endDate,
      period_start: props.startDate,
      types: props.types.length ? props.types : undefined,
      name: props.name,
    },
    {
      params: {
        cellsIds: props.cellsIds?.join(','),
      },
    },
  )

  return response.data
}

export const usePayments = (props: IPaymentsContextProps) => {
  const { data: me } = useMe()

  const query = useQuery(
    ['payment', me?.company_id, props],
    () => fetchPayment(props),
    {
      keepPreviousData: true,
      enabled: !!me && !!me.company_id && !!props.cellsIds?.length,
    },
  )
  return query
}
