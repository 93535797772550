import { Box, Checkbox, Text, Tooltip } from '@punto-ui/react'
import { FieldValues, useFormContext, useWatch } from 'react-hook-form'
import { ControlledCheckboxP } from './types'
import { Div } from '@/components/Div'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'

export const ControlledCheckbox = <TFieldValues extends FieldValues>({
  onValueChange,
  help,
  ...props
}: ControlledCheckboxP<TFieldValues>) => {
  const { control, setValue, getValues } = useFormContext()
  const value = useWatch({
    control,
    name: props.name,
    defaultValue: getValues(props.name),
  })

  const onCheckboxValueChange = (b: boolean) => {
    onValueChange?.(b)
    setValue(props.name, b as any)
  }

  return (
    <Box
      as="label"
      css={{
        background: 'transparent',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '$2',
        padding: '$0',
        opacity: props.disabled ? 0.5 : 1,
      }}
    >
      <Div>
        <Checkbox
          {...props}
          defaultChecked={value}
          onCheckedChange={onCheckboxValueChange}
          value={value}
          checked={value}
        />
      </Div>
      {!!props.label && (
        <Text size="sm" css={{ color: '$interface_dark_deep' }}>
          {props.label}
        </Text>
      )}
      {!!help && (
        <Tooltip message={help} arrow maxWidth={500}>
          <Div
            css={{
              height: 24,
              width: 24,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

              svg: {
                color: '$interface_dark_down',
                height: 20,
                width: 20,
              },
            }}
          >
            <QuestionMarkCircleIcon />
          </Div>
        </Tooltip>
      )}
    </Box>
  )
}
