import React, {
  forwardRef,
  ElementRef,
  useMemo,
  useEffect,
  useRef,
  useState,
  ComponentType,
} from 'react'
import { Input, DropdownContainer, DropdownItem } from './styles'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Progress, Text, TextInput, TextInputProps } from '@punto-ui/react'
import { CSS } from '@stitches/react'
import * as Popover from '@radix-ui/react-popover'
import { Div } from '../Div'
import { defaultAppearingAnimationKeyframe } from '@/pages/styles/animations'
import { useOutsideAlerters } from '@/hooks/useOutsideAlerters'

export interface DropdownProps extends TextInputProps {
  value: string
  onChangeValue?: (s: { label: string; value: string }) => void
  transformValue?: (s: string) => string
  containerCss?: CSS
  isLoading?: boolean
  alwaysFilter?: boolean
  InputComponent?: ComponentType<TextInputProps>
  options: {
    label: string
    value: string
  }[]
  containerWidth?: number
  defaultOption?: {
    label: string
    value: string
  }
}

export const DropdownV2 = forwardRef<ElementRef<typeof Input>, DropdownProps>(
  (
    {
      transformValue,
      options,
      value,
      isLoading,
      alwaysFilter,
      InputComponent = TextInput,
      containerWidth,
      ...props
    }: DropdownProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = React.useState(false)
    const [isOpen, setIsOpen] = React.useState(false)
    const [selectedOption, setSelectedOption] = React.useState<{
      label: string
      value: string
    }>(props.defaultOption || { label: '', value: '' })
    const [inputOptionValue, setInputOptionValue] = React.useState(
      props.defaultOption?.label || '',
    )

    const filteredOptions = useMemo(() => {
      return (inputOptionValue !== selectedOption?.label && inputOptionValue) ||
        alwaysFilter
        ? options?.filter((option) =>
            option.label.toLowerCase().includes(inputOptionValue.toLowerCase()),
          )
        : options
    }, [alwaysFilter, inputOptionValue, options, selectedOption])

    useEffect(() => {
      const option = options?.find((option) => option.value === value) ||
        props.defaultOption || { label: '', value: '' }

      if (value === selectedOption.value) {
        return
      }

      props.onChangeValue?.(option)
      setSelectedOption(option)
      setInputOptionValue(
        transformValue ? transformValue(option?.label) : option?.label,
      )
    }, [value])

    const timeoutRef = useRef<any>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const dropdownRef = useRef<HTMLUListElement>(null)

    const definitiveRef = ref || inputRef

    useOutsideAlerters([definitiveRef, dropdownRef] as any, () => {
      setIsOpen(false)
    })

    // const definitiveRefElementWidth = useMemo(() => {
    //   return (definitiveRef as any)?.current?.offsetWidth
    // }, [definitiveRef])

    const [definitiveRefElementWidth, setDefinitiveRefElementWidth] = useState(
      containerWidth || 0,
    )

    useEffect(() => {
      const cleanTimeout = setTimeout(() => {
        setDefinitiveRefElementWidth(
          containerWidth || (definitiveRef as any)?.current?.offsetWidth,
        )
      }, 5)

      return () => {
        clearTimeout(cleanTimeout)
      }
    }, [])

    return (
      <Popover.Root
        open={isFocused || isOpen}
        onOpenChange={(isOpen) => {
          if (!isOpen && isFocused) {
            return
          }

          setIsOpen(isOpen)

          if (isOpen) {
            timeoutRef.current = setTimeout(() => {
              if ((ref as any)?.current) {
                ;(ref as any).current.focus()
              }

              if (inputRef.current) {
                inputRef.current.focus()
              }
            }, 15)
          }
        }}
      >
        <DropdownContainer>
          <Popover.Trigger
            style={{
              all: 'unset',
              width: '100%',
            }}
          >
            <InputComponent
              css={{
                ...props.css,
              }}
              disabled={props.disabled}
              disclaimer={props.disclaimer}
              label={props.label}
              placeholder={props.placeholder}
              // ref={ref}
              ref={ref || inputRef}
              errorMessage={props.errorMessage}
              icon={isLoading ? <Progress /> : <ChevronDownIcon />}
              value={inputOptionValue}
              onFocus={(e) => {
                setIsFocused(true)
                if (props.onFocus) props.onFocus(e)
              }}
              onBlur={(e) => {
                setIsFocused(false)
                if (props.onBlur) props.onBlur(e)
              }}
              onChange={(e) => {
                e.preventDefault()
                setInputOptionValue(
                  transformValue
                    ? transformValue(e.target.value)
                    : e.target.value,
                )
                if (props.onChange) props.onChange(e)
              }}
            />
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content sideOffset={5} align="start" asChild>
              <Div
                ref={dropdownRef}
                as="ul"
                css={{
                  zIndex: 9999999,
                  maxHeight: 350,
                  width: definitiveRefElementWidth + 36,
                  overflowY: 'auto',

                  pointerEvents: 'auto',
                  overflowX: 'hidden',

                  backgroundColor: '$interface_light_pure',
                  borderRadius: '$md',

                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'stretch',

                  boxShadow: '0px 0px 16px rgba(52, 58, 64, 0.08)',
                  border: 'solid 1px $interface_light_down',
                  padding: '$2',

                  transition: 'opacity 0.5s ease-in-out',
                  animation: `${defaultAppearingAnimationKeyframe} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,

                  ...props.containerCss,
                }}
              >
                {/* <DropdownOptionsContainer
                isOpen={isFocused}
                css={{ ...props.containerCss }}
              > */}
                {filteredOptions?.map((option) => (
                  <DropdownItem
                    key={option?.value}
                    isSelected={selectedOption.value === option.value}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault()
                        props.onChangeValue?.(option)
                        setSelectedOption(option)
                        setInputOptionValue(option?.label)
                      }}
                    >
                      <Text variant="description">{option?.label}</Text>
                    </a>
                  </DropdownItem>
                ))}
                {/* </DropdownOptionsContainer> */}
              </Div>
            </Popover.Content>
          </Popover.Portal>
        </DropdownContainer>
      </Popover.Root>
    )
  },
)

DropdownV2.displayName = 'DropdownV2'
