import { queryClient } from '@/libs/react-query'
import { IExportPdfDTO, IReport } from '@/libs/react-query/types/reports'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleRequestExportPdf = async (data: IExportPdfDTO) => {
  const response = await apiV2.post<IReport>(
    `/report-builder/request-export-pdf-template`,
    data,
  )
  queryClient.invalidateQueries()

  return response.data
}

export const useHandleRequestExportPdf = () => {
  const mutation = useCustomMutation(
    ['useHandleExportPdf'],
    handleRequestExportPdf,
  )

  return mutation
}
