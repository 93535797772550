import dayjs from 'dayjs'
import { useMemo } from 'react'
import { Text, styled } from '@punto-ui/react'
import { IInputDatePickerV2Props } from '..'
import { Div } from '@/components/Div'

interface ICell {
  id: string
  value: string
}

interface IRow {
  id: string
  cells: ICell[]
}

const THead = styled('thead', {})

export const Calendar = (
  props: IInputDatePickerV2Props & {
    calendarDate: Date
    dates: Date[]
  },
) => {
  const rows = useMemo(() => {
    let startOfMonth = dayjs(props.calendarDate).startOf('month')
    const blankSpaces = startOfMonth.day()
    const daysInMonth = dayjs(props.calendarDate).daysInMonth()

    const rows: IRow[] = []
    let cells: ICell[] = []

    for (let i = 1; i <= blankSpaces; i++) {
      cells.push({
        id: i.toString(),
        value: '',
      })
    }

    for (let i = 1; i <= daysInMonth; i++) {
      cells.push({
        id: startOfMonth.toString(),
        value: i.toString(),
      })

      if (cells.length === 7) {
        rows.push({
          id: startOfMonth.toString(),
          cells,
        })

        cells = []
      }

      startOfMonth = startOfMonth.add(1, 'day')
    }

    if (cells.length > 0) {
      rows.push({
        id: startOfMonth.toString(),
        cells,
      })
    }

    return rows
  }, [props.calendarDate])

  const daysLabels = ['D', 'L', 'M', 'M', 'J', 'V', 'S']

  const isDateSelected = (date: dayjs.Dayjs) => {
    return props.dates.some((d) => date.isSame(dayjs(d), 'date'))
  }

  const isDateDisabled = (date: dayjs.Dayjs) => {
    const disabled = props.isDisabled ? props.isDisabled(date.toDate()) : false

    if (disabled) {
      return true
    }

    if (!props.maxDates) {
      return false
    }

    if (
      props.type === 'multiple' &&
      props.maxDates &&
      props.dates.length >= props.maxDates
    ) {
      return true
    }

    if (props.type === 'range' && props.maxDates && props.dates.length === 1) {
      const maximumAllowedDate = dayjs(props.dates[0]).add(
        props.maxDates,
        'days',
      )
      const minimumAllowedDate = dayjs(props.dates[0]).add(
        -1 * props.maxDates,
        'days',
      )

      return (
        maximumAllowedDate.isBefore(date, 'date') ||
        minimumAllowedDate.isAfter(date, 'date')
      )
    }
  }

  const isBetweenDates = (date: dayjs.Dayjs) => {
    const firstDate = props.dates[0]
    const secondDate = props.dates[1]

    return !!(
      firstDate &&
      date.isAfter(dayjs(firstDate), 'date') &&
      secondDate &&
      date.isBefore(dayjs(secondDate), 'date')
    )
  }

  const handleSelectDate = (date: dayjs.Dayjs) => {
    if (props.type === 'multiple') {
      const isDateAlreadySelected = props.dates.some((d) =>
        dayjs(d).isSame(date, 'date'),
      )

      const dates = isDateAlreadySelected
        ? [...props.dates].filter((d) => !dayjs(d).isSame(date, 'date'))
        : [...props.dates, date.toDate()]

      const sortedDates = dates.sort((a, b) =>
        dayjs(a).isBefore(dayjs(b), 'date') ? -1 : 1,
      )

      props.onChangeDates(sortedDates)
      return
    }

    if (props.type === 'single') {
      props.onChangeDates([date.toDate()])
      return
    }

    const hasFirstDate = props.dates[0]
    const hasSecondDate = props.dates[1]

    if (!hasFirstDate && props.type === 'range') {
      props.onChangeDates([date.toDate()])

      return
    }

    if (hasFirstDate && !hasSecondDate && props.type === 'range') {
      const newDates = [...props.dates, date.toDate()]
      const sortedNewDates = newDates.sort((a, b) =>
        dayjs(a).isBefore(dayjs(b), 'date') ? -1 : 1,
      )

      props.onChangeDates(sortedNewDates)
    }

    if (hasFirstDate && hasSecondDate && props.type === 'range') {
      props.onChangeDates([date.toDate()])
    }
  }

  return (
    <Div
      css={{
        padding: '$2',

        transition: 'all 0.2s ease-in-out',

        '& table': {
          width: '100%',
          tableLayout: 'fixed',
          borderSpacing: 0,
        },
      }}
    >
      <table>
        <THead
          css={{
            borderBottom: '1px solid',
            borderBottomColor: '$interface_dark_deep',
          }}
        >
          <tr>
            {daysLabels.map((day, index) => (
              <DayCell key={index} disabled isHeader>
                <DayCellFill>
                  <Text size="sm" css={{ color: '$interface_dark_down' }}>
                    {day}
                  </Text>
                </DayCellFill>
              </DayCell>
            ))}
          </tr>
        </THead>
        <tbody>
          {rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.cells.map((cell) => {
                  return (
                    <DayCell
                      key={cell.id}
                      isToday={
                        dayjs(cell.id).isSame(dayjs(), 'day') &&
                        !dayjs(cell.id).isSame(props.dates[0], 'day') &&
                        !dayjs(cell.id).isSame(props.dates[1], 'day')
                      }
                      selected={isDateSelected(dayjs(cell.id))}
                      isRange={props.type === 'range'}
                      isStartDate={
                        props.type === 'range' &&
                        !!(
                          props.dates[0] &&
                          dayjs(cell.id).isSame(dayjs(props.dates[0]), 'date')
                        )
                      }
                      isEndDate={
                        props.type === 'range' &&
                        !!(
                          props.dates[1] &&
                          dayjs(cell.id).isSame(dayjs(props.dates[1]), 'date')
                        )
                      }
                      between={
                        props.type !== 'multiple' &&
                        isBetweenDates(dayjs(cell.id))
                      }
                      disabled={
                        props.disabled ||
                        cell.value === '' ||
                        isDateDisabled(dayjs(cell.id))
                      }
                    >
                      <button
                        type="button"
                        disabled={
                          props.disabled ||
                          cell.value === '' ||
                          isDateDisabled(dayjs(cell.id))
                        }
                        onClick={
                          cell.value === ''
                            ? () => null
                            : () => handleSelectDate(dayjs(cell.id))
                        }
                      >
                        <DayCellFill>
                          <Text
                            variant="description"
                            css={{
                              color:
                                cell.value !== '' &&
                                isDateDisabled(dayjs(cell.id))
                                  ? '$interface_light_down'
                                  : '$interface_dark_down',
                            }}
                          >
                            {cell.value}
                          </Text>
                        </DayCellFill>
                      </button>
                    </DayCell>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </Div>
  )
}

export const DayCellFill = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '100%',
  aspectRatio: '1 / 1',
  zIndex: 2,
})

const DayCell = styled('td', {
  width: '100%',
  aspectRatio: '1 / 1',
  position: 'relative',

  transition: 'all 0.2s ease-in-out',

  '&:hover': {
    '& button': {
      backgroundColor: '$brand_primary_pure',
    },

    border: 0,

    [`& ${Text}`]: {
      color: '$interface_light_pure',
    },
  },

  '& button': {
    all: 'unset',
    width: '100%',
    aspectRatio: '1 / 1',
    cursor: 'pointer',
  },

  variants: {
    disabled: {
      true: {
        '&:hover': {
          [`& ${DayCellFill}`]: {
            backgroundColor: '$interface_light_pure',
          },

          [`& ${Text}`]: {
            color: '$interface_dark_up',
          },
        },
      },
    },
    isRange: {
      false: {
        '& button': {
          borderRadius: '$full',
        },
      },
    },
    isStartDate: {
      true: {
        '& button': {
          borderTopLeftRadius: '$md',
          borderBottomLeftRadius: '$md',
        },
      },
    },
    isEndDate: {
      true: {
        '& button': {
          zIndex: 2,
          borderTopRightRadius: '$md',
          borderBottomRightRadius: '$md',
        },
      },
    },
    selected: {
      true: {
        border: 'none',
        [`& ${DayCellFill}`]: {
          zIndex: 1,
        },

        '& button': {
          zIndex: 2,
          backgroundColor: '$brand_primary_pure',
        },

        [`& ${Text}`]: {
          color: '$interface_light_pure',
        },
      },
      false: {
        '&:hover': {
          '& button': {
            borderRadius: '$full',
          },
        },
      },
    },
    between: {
      true: {
        backgroundColor: '$interface_light_down',
      },
    },
    isHeader: {
      true: {
        '&:hover': {
          [`& ${Text}`]: {
            color: '$interface_dark_down',
          },
        },
      },
    },
    isToday: {
      true: {
        border: 'solid 1px $brand_primary_pure',
        borderRadius: '$full',
        [`& ${Text}`]: {
          color: '$brand_primary_pure',
        },
      },
    },
  },
})
