import { queryClient } from '@/libs/react-query'
import { IExportTemplateDTO, IReport } from '@/libs/react-query/types/reports'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleRequestExportTemplate = async (data: IExportTemplateDTO) => {
  const response = await apiV2.post<IReport>(
    `/report-builder/request-export-template`,
    data,
  )
  queryClient.invalidateQueries()

  return response.data
}

export const useHandleRequestExportTemplate = () => {
  const mutation = useCustomMutation(
    ['useHandleExportTemplate'],
    handleRequestExportTemplate,
  )

  return mutation
}
